<template>
    <div class="mt-6 mx-8 lg:mx-20 p-4 md:p-6 bg-white rounded-lg">
        <p class="flex space-x-2 items-center mb-6">
            <span><svgicon name="collectCheck" width="48" height="48" ></svgicon></span>
            <span class="text-lg md:text-2xl font-medium">Merci. Vos informations ont été envoyées avec succès</span>
        </p>
        <p class="mb-2">Cher client</p>
        <p class="mb-6 text-specialGrey">Nous vous remercions pour votre contribution à ce programme de mise à jour de vos données clients. </p>
        <a href="https://www.bni.ci/" target="_blank"><m-button :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg py-3.5 px-6'" :text="'Retour sur le portail de la BNI'"></m-button></a>
    </div>
</template>

<script>
export default {
    mounted() {
        this.$emit('emitBg', 'bg-bgGrey')
    },
}
</script>